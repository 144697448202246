import React, { useCallback, useContext, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import ButtonComp from "./ButtonComp";
import GAlogo from "../assets/GAlogo.svg";
import MainPage from "../pages/MainPage";
import { auth } from "../services/firebase";
import { AppContext } from "../App";
import {
  Avatar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { PROFILE_IMAGES } from "../config/default";
import LoginComp from "./LoginComp";
import logoo from "../assets/logoo.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CompSelect from "./CompSelect";
import { API_URL } from "../config/default";
import { ethers } from "ethers";
import ModalOne from "./ModalOne";
import { isMobile } from "react-device-detect";

const drawerWidth = 240;

// eslint-disable-next-line no-unused-vars

const DrawerComp = (props) => {
  const { windows } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const {
    setLoginResponse,
    loginResponse,
    loginPressed,
    setLoginPressed,
    setWalletLogin,
    walletLogin,
    setUserLocation,
  } = useContext(AppContext);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const opens = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [data, setdata] = useState({
    address: "",
    Balance: null,
  });
  const updatePublicAddress = useCallback(
    (publicAddress) => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("x-access-token", loginResponse?.token);

      var raw = JSON.stringify({
        publicAddress,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
      };

      fetch(`${API_URL}api/profile/updatePublicAddress`, requestOptions)
        .then(async (response) => {
          if (response.status === 200 || response.ok) {
            return Promise.resolve(response.json()); // This will end up in SUCCESS part
          } else if (response.status === 401) {
            auth.signOut().then(() => {
              if (window?.ethereum) window?.ethereum?.enable();
              setLoginResponse(null);
              setWalletLogin(null);
              localStorage.clear();
            });
          }
          const responseInJson = await Promise.resolve(response.json());
          return Promise.reject(responseInJson);
        })
        .then((res) => {
          console.log("updatePublicAddress", res);
          setWalletLogin(publicAddress);
        })
        .catch((error) => console.log("error", error));
    },
    [loginResponse?.token, setWalletLogin]
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const networks = {
    avax: {
      chainId: `0x${Number(43114).toString(16)}`,
      chainName: "Avalanche C-Chain",
      nativeCurrency: { name: "Avalanche", symbol: "AVAX", decimals: 18 },
      rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
      blockExplorerUrls: ["https://www.avax.network/"],
    },
    fuji: {
      chainId: `0x${Number(43113).toString(16)}`,
      chainName: "Avalanche Fuji Testnet",
      nativeCurrency: { name: "Avalanche", symbol: "AVAX", decimals: 18 },
      rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
      blockExplorerUrls: ["https://cchain.explorer.avax-test.network"],
    },
  };
  const chainId = 43113;
  const btnhandler = () => {
    if (loginResponse) {
      console.log(window?.ethereum);
      if (window.ethereum) {
        window.ethereum.enable();
        loginWallet();
      } else if (isMobile && !window?.ethereum) {
        window.open(
          `https://metamask.app.link/dapp/wallet.gamerarena.com?token=${loginResponse?.token}`,
          "_blank",
          "noreferrer"
        );
      } else {
        setOpen(true);
      }
    } else {
      setLoginPressed(true);
    }
  };

  const loginWallet = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const currentChainId = await provider
      .getNetwork()
      .then((network) => network.chainId);
    // Eğer şu anki blok zinciri istediğimiz blok zinciri değilse
    if (chainId !== currentChainId) {
      try {
        // Metamask'ı istediğimiz blok zincirine geçir
        await provider.send("wallet_switchEthereumChain", [
          { chainId: ethers.utils.hexlify(chainId) },
        ]);
        console.log(`Metamask switched to chainid : ${chainId} succesfully`);
      } catch (err) {
        console.log(
          `Error occured while switching chain to chainId ${chainId}, err: ${err.message} code: ${err.code}`
        );
        // Eğer blok zinciri bulunamadıysa ekle
        if (err.code === 4902) {
          try {
            await provider.send("wallet_addEthereumChain", [networks.fuji]);
          } catch (err) {
            console.log(
              `Error ocuured while adding new chain with chainId:${networks.fuji.chainId}, err: ${err.message}`
            );
          }
        }
      }
    }

    const accounts = await provider.listAccounts();
    updatePublicAddress(accounts[0]);
    console.log("acc", accounts);
    window.localStorage.setItem("wallet", accounts[0]);
    window.localStorage.getItem("wallet");
    const balance = await provider.getBalance(accounts[0]);
    setdata({
      Balance: ethers.utils.formatEther(balance),
    });
    console.log("balance", ethers.utils.formatEther(balance));
    const signer = provider.getSigner();
    const message = "Welcome to the Gamer Arena";
    const signature = await signer.signMessage(message);
    console.log("signature", signature);
  };
  const getbalance = (address) => {
    console.log("adres", address);
    // Requesting balance method
    window?.ethereum
      .request({
        method: "eth_sendTransaction",
        params: [address, "latest"],
      })
      .then((balance) => {
        // Setting balance
        setdata({
          Balance: ethers.utils.formatEther(balance),
        });
      });
  };

  // Function for getting handling all events
  const accountChangeHandler = (account) => {
    // Setting an address dat

    // Setting a balance
    getbalance(account);
  };

  const drawer = (
    <div>
      {loginResponse && (
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            justifyContent: "center",
            mt: 2,
          }}
        >
          <img
            src={logoo}
            style={{
              width: "151px",
              height: "20px",
            }}
          />
        </Box>
      )}
    </div>
  );
  console.log(loginResponse);

  const container =
    windows !== undefined ? () => window().document.body : undefined;

  function openLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLatitude = position.coords.latitude;
          const userLongitude = position.coords.longitude;

          // İstenilen enlem ve boylam aralığı (örneğin Türkiye koordinatları)
          const minLatitude = 36.0;
          const maxLatitude = 42.0;
          const minLongitude = 26.0;
          const maxLongitude = 45.0;

          // Konumun istenen aralıkta olup olmadığını kontrol et
          const isWithinRange =
            userLatitude >= minLatitude &&
            userLatitude <= maxLatitude &&
            userLongitude >= minLongitude &&
            userLongitude <= maxLongitude;

          console.log(
            "Kullanıcının konumu Türkiye içinde mi?",
            isWithinRange,
            userLatitude,
            userLongitude
          );
          setUserLocation(true);
        },
        (error) => {
          console.error("Konum alınamadı:", error.message);
        }
      );
    } else {
      console.error("Tarayıcı konum özelliğini desteklemiyor.");
    }
  }

  return (
    <Box sx={{ display: "flex", backgroundColor: "#0F0F12" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar sx={{ backgroundColor: "#0F0F12" }}>
          {/* <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
            {' '}
            <img
              src={GAlogo}
              style={{
                width: '24px',
                height: '24px',
              }}
            />
          </Box> */}

          <Box flexGrow={1} />
          {/* <CompSelect /> */}
          <ButtonComp
            text="Open Location"
            jc="flex-end"
            onClick={() => openLocation()}
          />

          {!loginResponse ? (
            <>
              <ButtonComp
                onClick={() => setLoginPressed(true)}
                text="Login"
                jc="flex-end"
              />
            </>
          ) : (
            <>
              <ModalOne open={open} setOpen={setOpen} />
              {/* {!walletLogin && (
                <ButtonComp
                  text="Connect MetaMask"
                  jc="flex-end"
                  onClick={btnhandler}
                />
              )}
              {walletLogin && (
                <Typography
                  sx={{ fontSize: '16px', color: 'white', fontWeight: 700 }}
                >
                  {walletLogin?.substring(0, 6) +
                    '...' +
                    walletLogin?.substring(walletLogin?.length - 4)}
                </Typography>
              )} */}

              <Avatar
                sx={{ ml: "2%" }}
                alt={
                  loginResponse?.nickname?.length <= 10
                    ? loginResponse?.nickname
                    : loginResponse?.nickname.substr(0, 10) + "..."
                }
                src={PROFILE_IMAGES + loginResponse?.avatar}
              />

              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 700,
                  marginLeft: "8px",
                  color: "#FFFFFF",
                  textOverflow: "ellipsis",
                }}
              >
                {loginResponse?.nickname.length <= 10
                  ? loginResponse?.nickname
                  : loginResponse?.nickname.substr(0, 10) + "..."}
              </Typography>
              <IconButton aria-label="arrow" onClick={handleClick}>
                <KeyboardArrowDownIcon sx={{ color: "white" }} />
              </IconButton>
              <Menu
                sx={{
                  py: 0,
                  "& .MuiMenu-list": {
                    background: "#262831",
                    borderRadius: 0,
                  },
                }}
                id="basic-menu"
                anchorEl={anchorEl}
                open={opens}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  sx={{ py: 0, backgroundColor: "#262831", color: "white" }}
                  onClick={() =>
                    auth.signOut().then(() => {
                      if (window?.ethereum) window?.ethereum?.enable();
                      setLoginResponse(null);
                      setWalletLogin(null);
                      setAnchorEl(null);
                      localStorage.clear();
                    })
                  }
                >
                  Logout
                </MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
      {/* <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. 
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              backgroundColor: '#181920',
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box> */}
      <Toolbar disableGutters />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" },
          mt: "68px",
        }}
      >
        <MainPage />
        {loginPressed && (
          <LoginComp
            open={loginPressed}
            handleClose={() => setLoginPressed(false)}
          />
        )}
      </Box>
    </Box>
  );
};

export default DrawerComp;
