import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCnOXz6xPRNUZrSK-_YGvx5EmAytVDq4b8",
  authDomain: "gamerarenamobile.firebaseapp.com",
  projectId: "gamerarenamobile",
  storageBucket: "gamerarenamobile.appspot.com",
  messagingSenderId: "1001244836424",
  appId: "1:1001244836424:web:35747ffdec6484e981ce7d",
  measurementId: "G-JP1FDFXLHE",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });

export const signInWithGoogle = () => auth.signInWithPopup(provider);

const appleProvider = new firebase.auth.OAuthProvider("apple.com");
export const signInWithApple = () => auth.signInWithPopup(appleProvider);

export default firebase;
