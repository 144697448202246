import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { createContext, useContext, useEffect, useState } from "react";
import "./App.css";
import DrawerComp from "./components/DrawerComp";
import { API_URL } from "./config/default";
import {
  EthereumClient,
  modalConnectors,
  walletConnectProvider,
} from "@web3modal/ethereum";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { avalanche, avalancheFuji } from "wagmi/chains";
import MainPage from "./pages/MainPage";
import { Web3Modal } from "@web3modal/react";
import { auth } from "../src/services/firebase";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export const AppContext = createContext();

export const projectId = "86c67c4282dec68752febf344ab251f3";
console.log("projectId", projectId);
// 2. Configure wagmi client
// const chains = [avalanche, avalancheFuji];
// const { provider } = configureChains(chains, [
//   walletConnectProvider({ projectId }),
// ]);
// export const wagmiClient = createClient({
//   autoConnect: true,
//   // connectors: [

//   //   new MetaMaskConnector({ chains }),
//   //   new WalletConnectConnector({
//   //     chains,
//   //     options: {
//   //       qrcode: true,
//   //     },
//   //   }),
//   // ],
//   connectors: modalConnectors({
//     appName: "ga-wallet",
//     chains,
//   }),
//   provider,
// });

// 3. Configure modal ethereum client
// export const ethereumClient = new EthereumClient(wagmiClient, chains);

export function AppContextWrapper({ children }) {
  const [loginResponse, setLoginResponse] = useState(null);
  const [walletId, setWalletId] = useState(null);
  const [coinInfos, setCoinInfos] = useState(null); //coin bilgileri
  const [loginPressed, setLoginPressed] = useState(false); //Sign in modalı açar
  const [walletLogin, setWalletLogin] = useState(false);
  const [isWalletEquel, setIsWalletEquel] = useState(false);
  const [AvailableAmount, setAvailableAmount] = useState("");
  const [AvailableWithdrawAmount, setAvailableWithdrawAmount] = useState("");
  const [userLocation, setUserLocation] = useState(false);

  const userWallet = (token) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-access-token", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(`${API_URL}api/profile/myWallet`, requestOptions)
      .then(async (response) => {
        if (response.status === 200 || response.ok) {
          return Promise.resolve(response.json()); // This will end up in SUCCESS part
        } else if (response.status === 401) {
          auth.signOut().then(() => {
            if (window?.ethereum) window?.ethereum?.enable();
            setLoginResponse(null);
            setWalletLogin(null);
            localStorage.clear();
          });
        }
        const responseInJson = await Promise.resolve(response.json());
        return Promise.reject(responseInJson);
      })
      .then((res) => {
        const publicAddress = res?.[0]?.publicAddress;

        setWalletId(res[0]);
        console.log("ews", res[0]);
        setLoginResponse((prev) => ({
          ...prev,
          wallet: res?.[0]?.count?.$numberDecimal,
        }));

        console.log("pblc", publicAddress);
        console.log("lgn", loginResponse);
        if (publicAddress) setWalletLogin(publicAddress);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token = params.get("token");
    let webToken = window.localStorage.getItem("Token");

    console.log(webToken);
    if (webToken) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("X-Access-Token", webToken);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      fetch(`${API_URL}api/profile/tokenUser`, requestOptions)
        .then(async (response) => {
          console.log("aaaaa", response);
          if (response.status === 200 || response.ok) {
            return Promise.resolve(response.json()); // This will end up in SUCCESS part
          } else if (response.status === 401) {
            auth.signOut().then(() => {
              if (window?.ethereum) window?.ethereum?.enable();
              setLoginResponse(null);
              setWalletLogin(null);
              localStorage.clear();
            });
          }

          const responseInJson = await Promise.resolve(response.json());
          return Promise.reject(responseInJson);
        })
        .then((res) => {
          console.log("tokenUser", res);

          setLoginResponse({ ...res, webToken });
          userWallet(webToken);
          if (res.ip) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
              method: "GET",
              headers: myHeaders,
            };

            fetch(`http://ip-api.com/json/${res.ip}`, requestOptions)
              .then(async (response) => {
                if (response.status === 200 || response.ok) {
                  return Promise.resolve(response.json()); // This will end up in SUCCESS part
                }
                const responseInJson = await Promise.resolve(response.json());
                return Promise.reject(responseInJson);
              })
              .then((res) => {
                setUserLocation(true);
              })
              .catch((error) => {
                console.log("Fetch error:", error);
                return error.text(); // Get the response text
              })
              .then((responseText) => {
                console.log("Response text:", responseText);
              });
          }
        })
        .catch((error) => console.log("error", error));
    } else if (token) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("x-access-token", token);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      fetch(`${API_URL}api/profile/tokenUser`, requestOptions)
        .then(async (response) => {
          if (response.status === 200 || response.ok) {
            return Promise.resolve(response.json()); // This will end up in SUCCESS part
          } else if (response.status === 401) {
            auth.signOut().then(() => {
              if (window?.ethereum) window?.ethereum?.enable();
              setLoginResponse(null);
              setWalletLogin(null);
              localStorage.clear();
            });
          }
          const responseInJson = await Promise.resolve(response.json());
          return Promise.reject(responseInJson);
        })
        .then((res) => {
          console.log("tokenUser", res);

          setLoginResponse({ ...res, token });
          userWallet(token);

          // window.localStorage.setItem("Token", token);
        })
        .catch((error) => console.log("error", error));
    }
    // eslint-disable-next-line no-restricted-globals
    history.replaceState({}, "", "/");
  }, []);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLatitude = position.coords.latitude;
          const userLongitude = position.coords.longitude;

          // İstenilen enlem ve boylam aralığı (örneğin Türkiye koordinatları)
          const minLatitude = 36.0;
          const maxLatitude = 42.0;
          const minLongitude = 26.0;
          const maxLongitude = 45.0;

          // Konumun istenen aralıkta olup olmadığını kontrol et
          const isWithinRange =
            userLatitude >= minLatitude &&
            userLatitude <= maxLatitude &&
            userLongitude >= minLongitude &&
            userLongitude <= maxLongitude;

          console.log(
            "Kullanıcının konumu Türkiye içinde mi?",
            isWithinRange,
            userLatitude,
            userLongitude
          );
          setUserLocation(true);
        },
        (error) => {
          console.error("Konum alınamadı:", error.message);
        }
      );
    } else {
      console.error("Tarayıcı konum özelliğini desteklemiyor.");
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        loginResponse,
        setLoginResponse,
        loginPressed,
        setLoginPressed,
        coinInfos,
        setCoinInfos,
        walletLogin,
        setWalletLogin,
        setWalletId,
        walletId,
        isWalletEquel,
        setIsWalletEquel,
        AvailableAmount,
        setAvailableAmount,
        AvailableWithdrawAmount,
        setAvailableWithdrawAmount,
        userLocation,
        setUserLocation,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});
function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      {/* <WagmiConfig client={wagmiClient}> */}
      <CssBaseline />
      <AppContextWrapper>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DrawerComp />
        </LocalizationProvider>
      </AppContextWrapper>
      {/* </WagmiConfig> */}
      {/* <Web3Modal projectId={projectId} ethereumClient={ethereumClient} /> */}
    </ThemeProvider>
  );
}

export default App;
