import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AppContext } from '../App';
import { useCallback } from 'react';
import { API_URL } from '../config/default';
import ButtonComp from './ButtonComp';
import { auth } from '../services/firebase';

const ModalDisconnect = ({ open, setOpen }) => {
  const {
    loginResponse,
    walletLogin,
    setLoginResponse,
    setWalletLogin,
    setLoginPressed,
    myWalletLogin,
    isWalletEquel,
    setIsWalletEquel,
  } = React.useContext(AppContext);

  const deletePublicAddress = useCallback(() => {
    console.log(walletLogin);
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('x-access-token', loginResponse?.token);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        publicAddress: walletLogin,
      }),
    };
    console.log('my', walletLogin);
    fetch(`${API_URL}api/profile/deletePublicAddress`, requestOptions)
      .then(async (response) => {
        if (response.status === 200 || response.ok) {
          return Promise.resolve(response.json()); // This will end up in SUCCESS part
        } else if (response.status === 401) {
          auth.signOut().then(() => {
            if (window?.ethereum) window?.ethereum?.enable();
            setLoginResponse(null);
            setWalletLogin(null);
            localStorage.clear();
          });
        }
        const responseInJson = await Promise.resolve(response.json());
        return Promise.reject(responseInJson);
      })
      .then((res) => {
        console.log('deleteePublicAddress', res);
        setWalletLogin(null);
      })
      .catch((error) => console.log('error', error));
  }, [loginResponse?.token, setWalletLogin, walletLogin]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{ backgroundColor: '#262831' }}
          id="alert-dialog-title"
        >
          {'Disconnect Wallet'}
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: '#262831' }}>
          <DialogContentText
            sx={{ color: 'white' }}
            id="alert-dialog-description"
          >
            Are you sure you want to sign out of the wallet?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: '#262831' }}>
          <ButtonComp
            onClick={handleClose}
            wd="130px"
            bg="#464853"
            text="Disagree"
          />
          <ButtonComp
            wd="130px"
            onClick={() => {
              deletePublicAddress();
              handleClose();
            }}
            bg="#FFFFFF"
            textColor="#464853"
            text="Agree"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalDisconnect;
