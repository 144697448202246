import React, { useCallback, useContext, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Button } from '@material-ui/core';
import { AppContext } from '../App';
import firebase, {
  auth,
  signInWithApple,
  signInWithGoogle,
} from '../services/firebase';
import AppleIcon from '@material-ui/icons/Apple';
import GoogleIcon from '../assets/googleIcon.svg';

import {
  Box,
  DialogActions,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import close from '../assets/close.svg';
import ButtonDetail from './ButtonDetail';
import { API_URL } from '../config/default';
// import { GoogleLogin, AppleLogin } from "react-google-login-component";

const AppleProcess = ({ onClick }) => {
  return (
    <Button
      variant="contained"
      color="inherit"
      style={{ width: '295px' }}
      startIcon={<AppleIcon />}
      onClick={onClick}
    >
      Sign in with Apple
    </Button>
  );
};
const GoogleProcess = ({ onClick }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      style={{ marginTop: '16px', width: '295px' }}
      startIcon={
        <img
          alt="Google-Icon"
          src={GoogleIcon}
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      }
      onClick={onClick}
    >
      Sign in with Google
    </Button>
  );
};
const LoginComp = ({ open, handleClose }) => {
  const { setLoginResponse, loginResponse, setWalletLogin } =
    useContext(AppContext);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const userWallet = useCallback(
    (token) => {
      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('x-access-token', token);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };

      fetch(`${API_URL}api/profile/myWallet`, requestOptions)
        .then(async (response) => {
          if (response.status === 200 || response.ok) {
            return Promise.resolve(response.json()); // This will end up in SUCCESS part
          } else if (response.status === 401) {
            auth.signOut().then(() => {
              if (window?.ethereum) window?.ethereum?.enable();
              setLoginResponse(null);
              setWalletLogin(null);
              localStorage.clear();
            });
          }
          const responseInJson = await Promise.resolve(response.json());
          return Promise.reject(responseInJson);
        })
        .then((res) => {
          const publicAddress = res?.[0]?.publicAddress;
          setLoginResponse((prev) => ({
            ...prev,
            wallet: res?.[0]?.count?.$numberDecimal,
          }));
          if (publicAddress) setWalletLogin(publicAddress);
        })
        .catch((error) => console.log('error', error));
    },
    [setLoginResponse, setWalletLogin]
  );

  const socialLoginWallet = useCallback(
    (token) => {
      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      var raw = JSON.stringify({
        token,
      });
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
      };

      fetch(`${API_URL}users/socialLoginWallet`, requestOptions)
        .then(async (response) => {
          if (response.status === 200 || response.ok) {
            return Promise.resolve(response.json()); // This will end up in SUCCESS part
          }
          const responseInJson = await Promise.resolve(response.json());
          return Promise.reject(responseInJson);
        })
        .then((res) => {
          console.log(res);
          setLoginResponse(res);
          userWallet(res?.token);
          window.localStorage.setItem('Token', res?.token);
        })
        .catch((error) => console.log('error', error));
    },
    [setLoginResponse, userWallet]
  );

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      console.log('usewr2', user);
      // auth.signOut()
      if (user && !loginResponse) {
        console.log('girdi');
        user.getIdToken().then((token) => {
          console.log(token);

          socialLoginWallet(token);
          handleClose();
        });
      }
    });
    return () => {
      unsubscribe();
    };
  }, [handleClose, loginResponse, socialLoginWallet]);
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        sx={{
          width: matches ? '400px' : 'auto',
          height: '350px',
          backgroundColor: '#262831',
        }}
      >
        <DialogActions sx={{ pr: 2, py: 2 }}>
          <img
            alt="closeIcon"
            src={close}
            onClick={handleClose}
            style={{
              width: '16px',
              height: '16px',
            }}
          />
        </DialogActions>
        <DialogTitle id="alert-dialog-title">
          <Typography
            sx={{
              fontSize: '32px',
              color: 'white',
              fontWeight: 700,
              textAlign: 'center',
            }}
          >
            Login With
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            display: 'flex',
            ...(!matches && { padding: '5% 0' }),
          }}
        >
          <AppleProcess onClick={signInWithApple} />
          <GoogleProcess onClick={signInWithGoogle} />
        </DialogContent>
        <DialogActions sx={{ m: '5%', justifyContent: 'center' }}>
          <ButtonDetail
            onClick={handleClose}
            text="Close"
            bg="#4C32CE"
            jc="center"
            width="295px"
          />
        </DialogActions>{' '}
      </Box>
    </Dialog>
  );
};

export default LoginComp;
