import { useWeb3Modal } from "@web3modal/react";
import { useState } from "react";

export const useWalletConnect = () => {
  const { open } = useWeb3Modal();

  async function onOpen() {
    await open();
  }

  return { onOpen };
};
