import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ozanpay from "../assets/Ozan-Transparan-Logo.webp";
import adv from "../assets/5.png";
import supadv from "../assets/6.png";
import { useEffect } from "react";
import { API_URL } from "../config/default";
import { AppContext } from "../App";
import { isMobile } from "react-device-detect";

const ModalTopupVisa = ({ openWalletTopupNew, setOpenWalletTopupNew }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const { loginResponse } = useContext(AppContext);
  const handleClose = () => {
    setOpenWalletTopupNew(false);
    setName(null);
    setSurname(null);
    setAgeConfirmation(false);
  };

  const [selectedAmount, setSelectedAmount] = useState(null);
  const [name, setName] = useState(null);
  const [surname, setSurname] = useState(null);
  const [ageConfirmation, setAgeConfirmation] = useState(false);
  const [val, setVal] = useState([]);
  const [payments, setPayments] = useState([]);

  const handleAmountSelect = (amount) => {
    setSelectedAmount(amount);
  };

  const handleAgeConfirmationChange = (event) => {
    setAgeConfirmation(event.target.checked);
  };
  const handleDepositClick = async () => {
    try {
      const selectedPayment = payments.find(
        (payment) =>
          parseFloat(payment.balance.$numberDecimal) === selectedAmount
      );

      if (!selectedPayment) {
        return;
      }

      const requestBody = {
        name,
        surname,
        id: selectedPayment._id,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": loginResponse?.token,
        },
        body: JSON.stringify(requestBody),
      };

      const response = await fetch(
        `${API_URL}api/deposit/requestPayment`,
        requestOptions
      );

      if (response.status === 200) {
        const responseJson = await response.json();
        // Yeni pencereyi aç
        if (isMobile) {
          window.open(responseJson.url, "_self", "noreferrer");
        } else {
          window.open(responseJson.url, "_blank", "height=500,width=800");
        }
        setOpenWalletTopupNew(false);
      } else {
        console.log("Error:", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-access-token", loginResponse?.token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(`${API_URL}api/deposit/paymentTypes`, requestOptions)
      .then(async (response) => {
        if (response.status === 200 || response.ok) {
          return Promise.resolve(response.json()); // This will end up in SUCCESS part
        }
        const responseInJson = await Promise.resolve(response.json());
        return Promise.reject(responseInJson);
      })
      .then((res) => {
        setPayments(res);
        setVal(res);
      })
      .catch((error) => console.log("error", error));
  }, []);

  return (
    <div>
      <Dialog
        open={openWalletTopupNew}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: "46px" }}
      >
        <Box
          sx={{
            width: matches ? "560px" : "auto",
            height: "auto",
            backgroundColor: "#000",
            border: "2px solid #262831",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.32)",
          }}
        >
          <DialogTitle
            sx={{
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            Top-up GamerArena Wallet
            <CloseIcon
              onClick={() => handleClose()}
              sx={{ cursor: "pointer" }}
            />
            {/* Kapatma (X) ikonunu ekledik */}
          </DialogTitle>
          <DialogContent>
            <Typography sx={{ pb: 2 }}>Select the Amount to Deposit</Typography>
            <Grid container spacing={4}>
              {val?.map((item) => {
                const isSelected =
                  selectedAmount === parseFloat(item.balance.$numberDecimal);

                return (
                  <Grid
                    key={item.balance.$numberDecimal}
                    xs={6}
                    sm={4}
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      sx={{
                        height: "100px",
                        width: "150px",
                        background: isSelected
                          ? " linear-gradient(118.02deg, #8001FF 0%, #64F7E5 121.76%)"
                          : "#181920",
                        color: isSelected
                          ? "#000"
                          : " linear-gradient(118.02deg, #8001FF 0%, #64F7E5 121.76%)",
                        position: "relative",
                      }}
                      onClick={() =>
                        handleAmountSelect(
                          parseFloat(item.balance.$numberDecimal)
                        )
                      }
                    >
                      {item.balance.$numberDecimal === "2500" && (
                        <img
                          alt="advantage"
                          src={adv}
                          style={{
                            width: 80, // Adjust the size of the image
                            position: "absolute",
                            top: "-30px", // Move the image down a bit
                            right: "-20px", // Move the image to the right
                            transform: "rotate(24deg)", // Apply more rotation
                          }}
                        />
                      )}
                      {item.balance.$numberDecimal === "5000" && (
                        <img
                          alt="superadvantage"
                          src={supadv}
                          style={{
                            width: 80, // Adjust the size of the image
                            position: "absolute",
                            top: "-30px", // Move the image down a bit
                            right: "-20px", // Move the image to the right
                            transform: "rotate(24deg)", // Apply more rotation
                          }}
                        />
                      )}
                      <React.Fragment>
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          {item.balance.$numberDecimal} GAU <br />
                          {parseFloat(item.balance.$numberDecimal) *
                            parseFloat(item.exchangeRate.$numberDecimal)}
                          ₺
                        </Typography>
                      </React.Fragment>
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
            <Grid
              sx={{
                mt: 4,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                disabled
                sx={{
                  display: "flex",
                  alignItems: "center",
                  background:
                    " linear-gradient(118.02deg, #8001FF 0%, #64F7E5 121.76%)",
                  p: 1,
                  borderRadius: 2,
                  width: "100%",
                }}
              >
                {/* <CreditCardIcon fontSize="large" sx={{ mr: 1 }} /> */}
                <img alt="ozanpay" src={ozanpay} width={175} />
              </Button>
            </Grid>
            <Grid sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
              <TextField
                label="Name"
                variant="outlined"
                size="small"
                sx={{
                  mt: 1,
                  "& input:-webkit-autofill": {
                    "-webkit-box-shadow": "0 0 0 1000px #000 inset",
                    "-webkit-text-fill-color": "#fff",
                  },
                }}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                label="Surname"
                variant="outlined"
                size="small"
                sx={{
                  mt: 1,
                  "& input:-webkit-autofill": {
                    "-webkit-box-shadow": "0 0 0 1000px #000 inset",
                    "-webkit-text-fill-color": "#fff",
                  },
                }}
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
              />
              <Grid item sx={{ mt: 2, display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  sx={{ mt: 2, fontSize: "small" }}
                  control={
                    <Checkbox
                      checked={ageConfirmation}
                      onChange={handleAgeConfirmationChange}
                    />
                  }
                  label="I confirm that I’m 18 years of age and older. By clicking the button below I accept all the legal responsibilities."
                />
              </Grid>
              <Button
                variant="contained"
                onClick={handleDepositClick}
                disabled={!ageConfirmation || !name || !surname}
                sx={{
                  mt: 2,
                  background:
                    "linear-gradient(118.02deg, #98FB98 0%, #008B8B 121.76%)",
                }}
              >
                Deposit
              </Button>
            </Grid>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
};

export default ModalTopupVisa;
