import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  useAccount,
  useConnect,
  useDisconnect,
  useEnsAvatar,
  useEnsName,
} from "wagmi";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TitleComp from "./TitleComp";
import CardOne from "./CardOne";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Chip, Grid, Link, useMediaQuery, useTheme } from "@mui/material";
import CardDetail from "./CardDetail";
import Transactions from "./Transactions";
import ArrowRed from "../assets/ArrowRed.svg";
import ArrowGreen from "../assets/ArrowGreen.svg";
import ModalOne from "../components/ModalOne";
import ModalWallet from "./ModalWallet";
import { AppContext } from "../App";
import { ethers } from "ethers";
import CardDetailWallet from "./CardDetailWallet";
import LoginComp from "./LoginComp";
import { API_URL } from "../config/default";
import { isMobile } from "react-device-detect";
import { useWalletConnect } from "../hooks/useWalletConnect";
import { useWeb3ModalNetwork } from "@web3modal/react";
import { auth } from "../services/firebase";
import CardDetailVisa from "./CardDetailVisa";
import red from "../assets/red.png";
import orange from "../assets/orange.png";
import green from "../assets/green.png";
function TabPanel(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ paddingLeft: 0 }}
    >
      {value === index && (
        <Box sx={{ py: 3, px: matches && 3, pl: matches ? 3 : 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const TabComp = () => {
  const {
    loginResponse,
    walletLogin,
    setLoginResponse,
    setWalletLogin,
    setLoginPressed,
    setWalletId,
  } = useContext(AppContext);

  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [data, setdata] = useState({
    address: "",
    Balance: null,
  });

  const updatePublicAddress = useCallback(
    (publicAddress) => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("x-access-token", loginResponse?.token);

      var raw = JSON.stringify({
        publicAddress,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
      };

      fetch(`${API_URL}api/profile/updatePublicAddress`, requestOptions)
        .then(async (response) => {
          if (response.status === 200 || response.ok) {
            return Promise.resolve(response.json()); // This will end up in SUCCESS part
          } else if (response.status === 401) {
            auth.signOut().then(() => {
              if (window?.ethereum) window?.ethereum?.enable();
              setLoginResponse(null);
              setWalletLogin(null);
              localStorage.clear();
            });
          }
          const responseInJson = await Promise.resolve(response.json());
          return Promise.reject(responseInJson);
        })
        .then((res) => {
          console.log("updatePublicAddress", res);
          setWalletLogin(publicAddress);
        })
        .catch((error) => console.log("error", error));
    },
    [loginResponse?.token, setWalletLogin]
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const AvaxChainId = `0x${Number(43114).toString(16)}`;
  const FujiChainId = `0x${Number(43113).toString(16)}`;
  const networks = {
    avax: {
      chainId: `0x${Number(43114).toString(16)}`,
      chainName: "Avalanche C-Chain",
      nativeCurrency: { name: "Avalanche", symbol: "AVAX", decimals: 18 },
      rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
      blockExplorerUrls: ["https://www.avax.network/"],
    },
    fuji: {
      chainId: `0x${Number(43113).toString(16)}`,
      chainName: "Avalanche Fuji Testnet",
      nativeCurrency: { name: "Avalanche", symbol: "AVAX", decimals: 18 },
      rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
      blockExplorerUrls: ["https://cchain.explorer.avax-test.network"],
    },
  };
  const chainId = 43113;
  const { onOpen } = useWalletConnect();
  // const { address, connector, isConnected } = useAccount();
  // const { data: ensAvatar } = useEnsAvatar({ address });
  // const { data: ensName } = useEnsName({ address });
  // const { connect, connectors, error, isLoading, pendingConnector } =
  //   useConnect();
  // const { disconnect } = useDisconnect();
  const btnhandler = () => {
    // console.log("address", address);
    // console.log(
    //   "ensName ? `${ensName} (${address})` : address",
    //   ensName ? `${ensName} (${address})` : address
    // );
    // return onOpen();
    if (loginResponse) {
      console.log(window?.ethereum);
      if (window.ethereum) {
        window.ethereum.enable();
        loginWallet();
      } else if (isMobile && !window?.ethereum) {
        window.open(
          `https://metamask.app.link/dapp/wallet.gamerarena.com?token=${loginResponse?.token}`,
          "_blank",
          "noreferrer"
        );
      } else {
        setOpen(true);
      }
    } else {
      setLoginPressed(true);
    }
  };
  const loginWallet = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const currentChainId = await provider
      .getNetwork()
      .then((network) => network.chainId);
    // Eğer şu anki blok zinciri istediğimiz blok zinciri değilse
    if (chainId !== currentChainId) {
      try {
        // Metamask'ı istediğimiz blok zincirine geçir
        await provider.send("wallet_switchEthereumChain", [
          { chainId: ethers.utils.hexlify(chainId) },
        ]);
        console.log(`Metamask switched to chainid : ${chainId} succesfully`);
      } catch (err) {
        console.log(
          `Error occured while switching chain to chainId ${chainId}, err: ${err.message} code: ${err.code}`
        );
        // Eğer blok zinciri bulunamadıysa ekle
        if (err.code === 4902 || err.code === -32603) {
          try {
            await provider.send("wallet_addEthereumChain", [networks.fuji]);
          } catch (err) {
            console.log(
              `Error ocuured while adding new chain with chainId:${networks.fuji.chainId}, err: ${err.message}`
            );
          }
        }
      }
    }

    const accounts = await provider.listAccounts();
    updatePublicAddress(accounts[0]);
    console.log("acc", accounts);
    window.localStorage.setItem("wallet", accounts[0]);

    const balance = await provider.getBalance(accounts[0]);
    setdata({
      Balance: ethers.utils.formatEther(balance),
    });
    console.log("balance", ethers.utils.formatEther(balance));
    const signer = provider.getSigner();
    const message = "Welcome to the Gamer Arena";
    const signature = await signer.signMessage(message);
    console.log("signature", signature);
  };
  const getbalance = (address) => {
    console.log("adres", address);
    // Requesting balance method
    window.ethereum
      .request({
        method: "eth_sendTransaction",
        params: [address, "latest"],
      })
      .then((balance) => {
        // Setting balance
        setdata({
          Balance: ethers.utils.formatEther(balance),
        });
      });
  };

  // Function for getting handling all events
  // const accountChangeHandler = (account) => {
  //   // Setting an address dat

  //   // Setting a balance
  //   getbalance(account);
  // };
  // const networkChanged = async (chainId) => {
  //   if (FujiChainId !== chainId && window.ethereum) {
  //     await window.ethereum.request({
  //       method: "wallet_addEthereumChain",
  //       params: [
  //         {
  //           ...networks["fuji"],
  //         },
  //       ],
  //     });
  //   }
  //   // console.log(AvaxChainId === chainId);
  //   // console.log("changed", { chainId });
  // };
  // useEffect(() => {
  //   if (window.ethereum) {
  //     window.ethereum.on("chainChanged", networkChanged);

  //     return () => {
  //       window.ethereum.removeListener("chainChanged", networkChanged);
  //     };
  //   }
  // }, []);

  const [appStoreURL, setappStoreURL] = useState("");
  useEffect(() => {
    // Kullanıcının işletim sistemini alın
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    console.log("usr", userAgent);
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    const isAndroid = /android/i.test(userAgent);
    if (isIOS) {
      // iOS ise App Store URL'sini belirleyin
      setappStoreURL(
        "https://apps.apple.com/tr/app/gamer-arena-play-compete-earn/id1641530825?l=tr"
      );
    } else if (isAndroid) {
      // Android ise Google Play URL'sini belirleyin
      setappStoreURL(
        "https://play.google.com/store/apps/details?id=com.gamerarenaapp"
      );
    }
    console.log("appSaaa", appStoreURL);
  }, []);

  return (
    <Box sx={{ width: "100%", minHeight: "90vh", p: 0 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", p: 0 }}>
        <div>
          <Tabs
            // inkBarStyle={{ background: 'yellow' }}
            sx={{
              "& .MuiTab-root": {
                color: "#8E909A !important",
                "&.Mui-selected": {
                  color: "white !important",
                },
              },
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor: "red",
              },
            }}
            value={value}
            onChange={handleChange}
          >
            <Tab label="Wallet" />
            {/* <Tab disabled label="Staking" />
            <Chip
              label="Coming Soon"
              sx={{
                backgroundColor: "#1C1E26",
                color: "#B8B8B9",
                mt: 1,
                fontSize: "10px",
              }}
            /> */}
          </Tabs>
        </div>
      </Box>
      <TabPanel value={value} index={value}>
        {value === 0 && (
          <Grid container direction="row" spacing={3}>
            <Grid item sm={6} xs={12} sx={{ pl: 0 }}>
              <TitleComp title="GamerArena Wallet" />

              {!loginResponse ? (
                <>
                  <CardOne buttonn text="Login to see your balance" />
                </>
              ) : (
                // <CardDetail />
                <CardDetailVisa />
              )}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Link href={"https://gamerarenaapp.page.link/ads"} passHref>
                <a>
                  <img
                    alt="Tournament"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      minHeight: isMobile ? "100px" : "auto",
                      marginTop: "3%",
                    }}
                    src={red}
                  />
                </a>
              </Link>
              <Link href={"https://gamerarenaapp.page.link/earngau"} passHref>
                <a>
                  <img
                    alt="Tournament"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      minHeight: isMobile ? "100px" : "auto",
                      marginTop: "1%",
                    }}
                    src={green}
                  />
                </a>
              </Link>
              <Link
                href={"https://gamerarenaapp.page.link/referfriend"}
                passHref
              >
                <a>
                  <img
                    alt="Tournament"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      minHeight: isMobile ? "100px" : "auto",
                      marginTop: "1%",
                    }}
                    src={orange}
                  />
                </a>
              </Link>
            </Grid>
            {/* Connected Wallet */}
            {/* <Grid item sm={6} xs={12} sx={{ pl: 0, height: '100%' }}>
              <TitleComp title="Connected Wallet" />
              <ModalOne open={open} setOpen={setOpen} />
              {!walletLogin ? (
                <CardOne
                  icon
                  textPlus="Connect wallet"
                  onClickWallet={btnhandler}
                />
              ) : (
                <CardDetailWallet />
              )}
            </Grid> */}
            {/* <Grid item sm={12} xs={12} sx={{ pl: 0 }}>
              <TitleComp title="My Transactions" />
              {!loginResponse ? (
                <CardOne
                  wallets
                  textTwo="Login to see your transactions"
                  buttonn
                />
              ) : (
                <Grid
                  container
                  sm={12}
                  direction="column"
                  alignItems="center"
                  justify="center"
                  sx={{ pb: "3%" }}
                >
                  <Grid item sm={12} sx={{ width: "100%", height: "100%" }}>
                    <Card
                      sx={{
                        backgroundColor: "#262831",
                        borderRadius: 3,
                      }}
                    >
                      <Transactions
                        src={ArrowRed}
                        text="1v1 Reward"
                        description="May 2"
                        priceColor="#EB2C44"
                        price="5"
                      />
                      <Transactions
                        src={ArrowGreen}
                        text="Nitelik Ödülü"
                        description="May 1"
                        priceColor="#48B474"
                        price="100"
                      />
                    </Card>
                  </Grid>
                  <ModalWallet />
                </Grid>
              )}
            </Grid> */}
          </Grid>
        )}
      </TabPanel>
    </Box>
  );
};

export default TabComp;
