import {
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import vektor from "../assets/Vector.svg";
import GAU from "../assets/GAU.svg";
import ButtonDetail from "./ButtonDetail";
import iconWallet from "../assets/IconWallet.svg";
import icon2 from "../assets/Icon2.svg";
import { AppContext } from "../App";
import ModalDisconnect from "./ModalDisconnect";
import ModalTopupVisa from "./ModalTopupVisa";
import ModalWithdraw from "./ModalWithdraw";
import { API_URL } from "../config/default";
import { auth } from "../services/firebase";
import ModalWithdrawPapara from "./ModalWithdrawPapara";

const CardDetailVisa = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const matchesS = useMediaQuery(theme.breakpoints.up("sm"));
  const [openWallet, setOpenWallet] = useState(false);
  const [openWalletTopup, setOpenWalletTopup] = useState(false);
  const [openWalletTopupNew, setOpenWalletTopupNew] = useState(false);
  const [openWalletWithdraw, setOpenWalletWithdraw] = useState(false);

  const [openConnectWallet, setOpenConnectWallet] = useState(false);

  const {
    loginResponse,
    walletLogin,
    setWalletLogin,
    setLoginPressed,
    setLoginResponse,
    walletId,
    setWalletId,
    userLocation,
  } = useContext(AppContext);

  console.log("userLoca", userLocation);

  const [open, setOpen] = React.useState(false);

  // useEffect(() => {
  //   let token = window.localStorage.getItem("Token");
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append("x-access-token", token);

  //   var requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //   };

  //   fetch(`${API_URL}api/profile/myWallet`, requestOptions)
  //     .then(async (response) => {
  //       if (response.status === 200 || response.ok) {
  //         return Promise.resolve(response.json()); // This will end up in SUCCESS part
  //       } else if (response.status === 401) {
  //         auth.signOut().then(() => {
  //           if (window?.ethereum) window?.ethereum?.enable();
  //           setLoginResponse(null);
  //           setWalletLogin(null);
  //           localStorage.clear();
  //         });
  //       }
  //       const responseInJson = await Promise.resolve(response.json());
  //       return Promise.reject(responseInJson);
  //     })
  //     .then((res) => {
  //       const publicAddress = res?.[0]?.publicAddress;

  //       setWalletId(res[0]);
  //       setLoginResponse((prev) => ({
  //         ...prev,
  //         wallet: res?.[0]?.count?.$numberDecimal,
  //       }));

  //       if (publicAddress) setWalletLogin(publicAddress);
  //     })
  //     .catch((error) => console.log("error", error));
  // }, [openWalletTopupNew, openWalletWithdraw]);

  return (
    <>
      <Card
        sx={{
          background:
            "linear-gradient(112.1deg, #EB2A44 17.84%, #8C49E2 103.4%)",
          borderRadius: 6,
          height: matches ? "200px" : "255px",
        }}
      >
        <CardContent sx={{ pb: matchesS ? "16px" : 0 }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: "1%" }}
          >
            <Grid item justifyContent="flex-start">
              <List>
                <ListItem>
                  <Typography
                    sx={{ opacity: 0.7, fontSize: "12px", color: "#FFFFFF" }}
                  >
                    @
                    {loginResponse?.nickname?.length <= 10
                      ? loginResponse?.nickname
                      : loginResponse?.nickname.substr(0, 10) + "..."}
                  </Typography>
                </ListItem>
                <ListItem sx={{ py: 0 }}>
                  <Typography sx={{ fontSize: "12px", color: "#FFFFFF" }}>
                    {walletId?._id?.substring(0, 3) +
                      "..." +
                      walletId?._id?.substring(walletId?._id?.length - 3)}
                  </Typography>
                </ListItem>
              </List>
            </Grid>
            {/* <Grid item justifyContent="flex-end">
              {" "}
              <Tooltip title="Metin1" arrow>
                <img
                  src={vektor}
                  style={{
                    width: "16px",
                    height: "16px",
                  }}
                />
              </Tooltip>
            </Grid> */}
          </Grid>
        </CardContent>
        <CardContent sx={{ p: 0, pb: 0 }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: "1%" }}
          >
            <Grid item justifyContent="flex-start">
              <List>
                <ListItem>
                  <img
                    src={GAU}
                    style={{
                      width: "49.74px",
                      height: "50px",
                    }}
                  />
                  <Typography
                    sx={{ fontSize: "24px", color: "#FFFFFF", px: 1 }}
                  >
                    {Number(loginResponse?.wallet).toFixed(2) ?? "-"}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "16px", color: "#FFFFFF", mt: "3%" }}
                  >
                    GAU
                    {/* . $10.5 */}
                  </Typography>
                </ListItem>
              </List>
            </Grid>
            <Grid
              item
              sx={{ pl: matches ? 0 : "5%" }}
              justifyContent={matches ? "flex-end" : "center"}
            >
              <List sx={{ mr: "15%" }}>
                <ListItem
                  sx={{
                    p: 0,
                    justifyContent: "flex-end",
                    ml: !matches && "15%",
                  }}
                >
                  {/* <ButtonDetail
                    text="Top-Up"
                    onClick={() => setOpenWalletTopupNew(true)}
                    bg="#FFFFFF"
                    src={iconWallet}
                    icon
                    width={matches ? "160px" : "143px"}
                  /> */}
                  {/* {userLocation && (
                    <ButtonDetail
                      text="Withdraw"
                      color="white"
                      border={1.5}
                      width={matches ? "160px" : "143px"}
                      onClick={() => setOpenWalletWithdraw(true)}
                      src={icon2}
                      icon
                    />
                  )} */}
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <ModalDisconnect open={open} setOpen={setOpen} />
      <ModalTopupVisa
        openWalletTopupNew={openWalletTopupNew}
        setOpenWalletTopupNew={setOpenWalletTopupNew}
      />
      <ModalWithdrawPapara
        openWalletWithdraw={openWalletWithdraw}
        setOpenWalletWithdraw={setOpenWalletWithdraw}
        wallet={Number(loginResponse?.wallet).toFixed(2) ?? "-"}
      />
    </>
  );
};

export default CardDetailVisa;
