import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import papara from "../assets/papara.svg";
import { useEffect } from "react";
import { API_URL } from "../config/default";
import { AppContext } from "../App";
import close from "../assets/close.svg";
import RankY from "../assets/RankY.svg";
import ButtonDetail from "./ButtonDetail";
import { MuiTelInput } from "mui-tel-input";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const ModalWithdrawPapara = ({
  openWalletWithdraw,
  setOpenWalletWithdraw,
  wallet,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const { loginResponse } = useContext(AppContext);
  const handleClose = () => {
    setOpenWalletWithdraw(false);
    setName(null);
    setSurname(null);
    setDoneWallet(false);
  };
  const date = dayjs(new Date());
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [name, setName] = useState(null);
  const [surname, setSurname] = useState(null);
  const [doneWallet, setDoneWallet] = useState(false);
  const [val, setVal] = useState([]);
  const [payments, setPayments] = useState([]);
  const [phone, setPhone] = React.useState("");
  const [birthDate, setBirthDate] = React.useState(date);

  const handleChange = (newPhone) => {
    console.log("newPhone", newPhone);
    setPhone(newPhone);
  };

  const handleAmountSelect = (amount) => {
    setSelectedAmount(amount);
  };

  const handleDepositClick = async () => {
    try {
      const selectedPayment = payments.find(
        (payment) =>
          parseFloat(payment.balance.$numberDecimal) === selectedAmount
      );

      if (!selectedPayment) {
        return;
      }

      const birth = birthDate.$d.toISOString().slice(0, 10);
      const phoneNumber = phone.replace(/\s+/g, "");

      const requestBody = {
        TCNo: name,
        paparaId: surname,
        phoneNumber: phoneNumber,
        birthdate: birth,
        types: selectedPayment.types,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": loginResponse?.token,
        },
        body: JSON.stringify(requestBody),
      };

      const response = await fetch(
        `${API_URL}api/papara/requestPayment`,
        requestOptions
      );

      if (response.status === 200) {
        const responseJson = await response.json();

        setDoneWallet(true);
      } else {
        console.log("Error:", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-access-token", loginResponse?.token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(`${API_URL}api/papara/paymentTypes`, requestOptions)
      .then(async (response) => {
        if (response.status === 200 || response.ok) {
          return Promise.resolve(response.json()); // This will end up in SUCCESS part
        }
        const responseInJson = await Promise.resolve(response.json());
        return Promise.reject(responseInJson);
      })
      .then((res) => {
        setPayments(res);
        setVal(res);
      })
      .catch((error) => console.log("error", error));
  }, []);

  return (
    <div>
      {doneWallet ? (
        <Dialog
          open={openWalletWithdraw}
          onClose={() => handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ borderRadius: "46px" }}
        >
          <DialogActions sx={{ pr: 3, py: 2 }}>
            <img
              src={close}
              onClick={handleClose}
              style={{
                width: "16px",
                height: "16px",
              }}
            />
          </DialogActions>
          <Box sx={{ px: matches ? "7%" : "1%" }}>
            <DialogContent
              sx={{ display: "flex", justifyContent: "center", p: 0 }}
            >
              <img
                src={RankY}
                style={{
                  width: "300px",
                  height: "300px",
                }}
              />
            </DialogContent>
            <DialogContent
              sx={{ display: "flex", justifyContent: "center", p: 1 }}
            >
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: 700,
                  color: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                Withdraw request has completed.
              </Typography>
            </DialogContent>
            <DialogContent
              sx={{ display: "flex", justifyContent: "center", py: 0 }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "#8E909A",
                  textAlign: "center",
                }}
              >
                After withdrawal requests, our support team checks all your
                duels and tournaments. Requests are approved after these checks.
                During busy periods, the approval of your withdrawal request can
                take up to <b>2 weeks</b> Thank you for your patience. Keep
                enjoying and game on!
              </Typography>
            </DialogContent>
            <DialogActions
              sx={{ m: "7%", mt: "11%", justifyContent: "center" }}
            >
              <ButtonDetail
                onClick={handleClose}
                text="Done"
                jc="center"
                width="295px"
                color="#FFFFFF"
                bgg="linear-gradient(95.41deg, #7021BF 0%, #4C32CE 100%)"
              />
            </DialogActions>
          </Box>
        </Dialog>
      ) : (
        <Dialog
          open={openWalletWithdraw}
          onClose={() => handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ borderRadius: "46px" }}
        >
          <Box
            sx={{
              width: matches ? "560px" : "auto",
              height: "auto",
              backgroundColor: "#000",
              border: "2px solid #262831",
              boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.32)",
            }}
          >
            <DialogTitle
              sx={{
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              Withdraw GamerArena Wallet
              <CloseIcon
                onClick={() => handleClose()}
                sx={{ cursor: "pointer" }}
              />
              {/* Kapatma (X) ikonunu ekledik */}
            </DialogTitle>
            <DialogContent>
              <Typography sx={{ pb: 2 }}>
                Select the Amount to Withdraw
              </Typography>
              <Grid container spacing={4}>
                {val?.map((item) => {
                  const isSelected =
                    selectedAmount === parseFloat(item.balance.$numberDecimal);

                  return (
                    <Grid
                      key={item.balance.$numberDecimal}
                      xs={6}
                      sm={4}
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        sx={{
                          height: "100px",
                          width: "150px",
                          background: isSelected
                            ? " linear-gradient(118.02deg, #8001FF 0%, #64F7E5 121.76%)"
                            : "#181920",
                          color: isSelected
                            ? "#000"
                            : " linear-gradient(118.02deg, #8001FF 0%, #64F7E5 121.76%)",
                          position: "relative",
                        }}
                        disabled={
                          wallet < parseFloat(item.balance.$numberDecimal)
                        }
                        onClick={() =>
                          handleAmountSelect(
                            parseFloat(item.balance.$numberDecimal)
                          )
                        }
                      >
                        <React.Fragment>
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            {item.balance.$numberDecimal} GAU <br />
                            {parseFloat(item.balance.$numberDecimal) *
                              parseFloat(item.exchangeRate.$numberDecimal)}
                            ₺
                          </Typography>
                        </React.Fragment>
                      </Button>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid
                sx={{
                  mt: 4,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  disabled
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    background:
                      " linear-gradient(118.02deg, #8001FF 0%, #64F7E5 121.76%)",
                    p: 2,
                    borderRadius: 2,
                    width: "100%",
                  }}
                >
                  {/* <CreditCardIcon fontSize="large" sx={{ mr: 1 }} /> */}
                  <img alt="papara" src={papara} width={300} />
                </Button>
              </Grid>
              <Grid sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
                <TextField
                  label="TC ID Number"
                  variant="outlined"
                  size="small"
                  sx={{
                    mt: 1,
                    "& input:-webkit-autofill": {
                      "-webkit-box-shadow": "0 0 0 1000px #000 inset",
                      "-webkit-text-fill-color": "#fff",
                    },
                  }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  label="Papara Id"
                  variant="outlined"
                  size="small"
                  sx={{
                    mt: 1,
                    "& input:-webkit-autofill": {
                      "-webkit-box-shadow": "0 0 0 1000px #000 inset",
                      "-webkit-text-fill-color": "#fff",
                    },
                  }}
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                />

                <MuiTelInput
                  label="Phone Number"
                  defaultCountry="TR"
                  size="small"
                  sx={{
                    mt: 1,
                    "& input:-webkit-autofill": {
                      "-webkit-box-shadow": "0 0 0 1000px #000 inset",
                      "-webkit-text-fill-color": "#fff",
                    },
                  }}
                  value={phone}
                  onChange={handleChange}
                />

                <DesktopDatePicker
                  sx={{
                    mt: 1,
                    "& input:-webkit-autofill": {
                      "-webkit-box-shadow": "0 0 0 1000px #000 inset",
                      "-webkit-text-fill-color": "#fff",
                    },
                  }}
                  label="Birth Date"
                  value={birthDate}
                  onChange={(newValue) => setBirthDate(newValue)}
                />

                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#8E909A",
                    mt: 2,
                  }}
                >
                  After withdrawal requests, our support team checks all your
                  duels and tournaments. Requests are approved after these
                  checks. During busy periods, the approval of your withdrawal
                  request can take up to <b>2 weeks</b> Thank you for your
                  patience. Keep enjoying and game on!
                </Typography>

                <Button
                  variant="contained"
                  onClick={handleDepositClick}
                  disabled={!name || !surname || !birthDate || !phone}
                  sx={{
                    mt: 2,
                    background:
                      "linear-gradient(118.02deg, #98FB98 0%, #008B8B 121.76%)",
                  }}
                >
                  Withdraw
                </Button>
              </Grid>
            </DialogContent>
          </Box>
        </Dialog>
      )}
    </div>
  );
};

export default ModalWithdrawPapara;
