import { Grid } from "@mui/material";
import React from "react";
import CardOne from "../components/CardOne";
import TabComp from "../components/TabComp";
import TitleComp from "../components/TitleComp";

const MainPage = () => {
  return <TabComp />;
};

export default MainPage;
